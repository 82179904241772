<template>
  <div class="container no_touch">

    <van-button class="button" plain type="info" @click="onClickRight()" square>添加</van-button>

    <div class="item-safe-area" v-for="item in response.Items" :key="item.FenceId">
      <div class="item-contr-left" @click="onClickRight(item)">
        <img class="item-icon" :src="getIcon(item.AlarmType)"/>
        <div class="item-contr-span">
          <span>{{ item.FenceName }}</span>
          <span class="address">{{ item.Address }}</span>
        </div>
      </div>
      <van-switch class="item-switch" v-model="item.InUse" @change="onSwitchChange(item)"/>
    </div>

  </div>
</template>

<script>

import {MapType} from "@/constants/contants";
import {post} from "@/router/httpUtils";
import {Toast} from "vant";

export default {
  name: "SafeAreaList",
  data() {
    return {
      response: {
        Items: '',
        State: Number
      },
    }
  },
  mounted() {
    this.getGeofenceList()
  },
  methods: {
    onClickRight(item) {
      console.log(item)
      if (!item) {
        item = {
          Longitude: localStorage.lng,
          Latitude: localStorage.lat,
          FenceName: '安全区域',
          Radius: 100
        }
      }
      this.$router.push({
        name: 'SafeArea',
        params: item
      })
    },
    getGeofenceList() {
      Toast.loading({
        message: '加载中...',
        duration: 0
      })
      post('/api/Geofence/GeofenceList', {
        "DeviceId": localStorage.DeviceId,
        "MapType": MapType,
        "Token": localStorage.Token
      }).then(response => {
        console.log(response);
        this.response = response
      }).catch(function (error) {
        console.log(error);
      }).then(() => {
        Toast.clear()
      })
    },
    getIcon(type) {
      let uri
      switch (type) {
        case 1:
          uri = require('../assets/geofence_mark_in.png')
          break
        case 2:
          uri = require('../assets/geofence_mark_out.png')
          break
        case 3:
          uri = require('../assets/geofence_mark_in_out.png')
          break
        default:
          uri = require('../assets/geofence_mark_default.png')
      }
      return uri
    },
    onSwitchChange(item) {
      this.changeGeofenceStatus(item)
    },
    changeGeofenceStatus(item) {
      console.log(item)
      post('/api/Geofence/ChangeFenceStatus', item)
          .then(response => {
            // handle success
            console.log(response);
            this.getGeofenceList()
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
    }
  }
}
</script>

<style scoped>
.container {

}

.item-safe-area {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid gray;
  padding: 10px;
}

.item-contr-left {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.item-icon {
  width: 30px;
  height: 30px;
}

.item-contr-span {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.item-switch {
  justify-self: end;
}

.address {

}

.button {
  width: 100%;
  position: absolute;
  bottom: 0;
}
</style>